#allInOneLogo{
    margin: 30px auto;
    width: 150px;
}
#allInOneLogo>img{
    width: 150px
}
#allInOneLogo>h2{
    text-align: center;
    margin-top: 6px;
    font-family: monospace;
}

.allInOneSection{
    display: block;
    background: linear-gradient(66deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.15));
    padding: 15px;
}
.allInOneSectionOdd{
    margin: 15px 0;
    display: block;
    background: linear-gradient(66deg, rgba(81, 81, 81, 0.45), rgba(0, 0, 0, 0.15));
    padding: 15px;
}
.spinner{
    margin: auto;
    text-align: center;
}


#hueConnectCard{
    background: rgba(255, 255, 255, 0.15);
    padding: 24px;
    margin: auto;
    border-radius: 6px;
    /*border: 3px solid black;*/
    min-height: 39px;
}
#hueConnectCard>h2{
    margin: 6px 15px 0 0;
    float: left;
}
#hueConnectCard>span{
    margin: 3px;
    display: inline-block;
    /* float: left; */
    text-align: center;
}
#hueConnectBtnTxt{
    margin-right: 9px;
}
#linkIcon{
    width: 30px;
    height: 30px;
    background: #00000073;
    padding: 6px;
    border-radius: 9px;
    float: left;
    margin-right: 15px;
}


.lightsItem{
    background: rgba(255, 255, 255, 0.55);
    margin: 3px;
    border-radius: 6px;
}
.unreachableLight{
    background: #9c9c9c94;
    background-image: url("../img/bg_pattern_01.png");
}
.reachableLight span{
    color: white;
}

.lightIconLeft{
    margin-top: 4px;
}

.lightIconLeftSpinner{
    margin-left: 10px!important;
    margin-top: -5px!important;
}

.lighNameCheckBox{
    /*padding: 0px 6px 0 21px!important;*/
    margin: -13px -4px -14px 0;
}

















.colorimation {
    -webkit-animation:colorchange 20s infinite alternate;
}

@-webkit-keyframes colorchange {
    0% {

        color: blue;
    }

    10% {

        color: #8e44ad;
    }

    20% {

        color: #1abc9c;
    }

    30% {

        color: #d35400;
    }

    40% {

        color: blue;
    }

    50% {

        color: #34495e;
    }

    60% {

        color: blue;
    }

    70% {

        color: #2980b9;
    }
    80% {

        color: #f1c40f;
    }

    90% {

        color: #2980b9;
    }

    100% {

        color: pink;
    }
}