body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    width: 100wh;
    height: 90vh;
    color: #fff;
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background-size: 400% 400%;
    -webkit-animation: Gradient 27s infinite;
    animation: Gradient 27s infinite;
    background-attachment: fixed;
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




/* =  = = =  = = = =*/

.app {
  background: #209cee;
  padding: 30px;
  height: 100vh;
}

.todo-list {
  background: #e8e8e8;
  border-radius: 4px;
  padding: 5px;
  max-width: 400px;
}

.todo {
  background: #fff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  padding: 3px 10px;
  font-size: 12px;
  margin-bottom: 6px;
  border-radius: 3px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}


#versionNumber{
  color: #5db9e7;
  font-size: .72em;
}
#allInOneLogo{
    margin: 30px auto;
    width: 150px;
}
#allInOneLogo>img{
    width: 150px
}
#allInOneLogo>h2{
    text-align: center;
    margin-top: 6px;
    font-family: monospace;
}

.allInOneSection{
    display: block;
    background: linear-gradient(66deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.15));
    padding: 15px;
}
.allInOneSectionOdd{
    margin: 15px 0;
    display: block;
    background: linear-gradient(66deg, rgba(81, 81, 81, 0.45), rgba(0, 0, 0, 0.15));
    padding: 15px;
}
.spinner{
    margin: auto;
    text-align: center;
}


#hueConnectCard{
    background: rgba(255, 255, 255, 0.15);
    padding: 24px;
    margin: auto;
    border-radius: 6px;
    /*border: 3px solid black;*/
    min-height: 39px;
}
#hueConnectCard>h2{
    margin: 6px 15px 0 0;
    float: left;
}
#hueConnectCard>span{
    margin: 3px;
    display: inline-block;
    /* float: left; */
    text-align: center;
}
#hueConnectBtnTxt{
    margin-right: 9px;
}
#linkIcon{
    width: 30px;
    height: 30px;
    background: #00000073;
    padding: 6px;
    border-radius: 9px;
    float: left;
    margin-right: 15px;
}


.lightsItem{
    background: rgba(255, 255, 255, 0.55);
    margin: 3px;
    border-radius: 6px;
}
.unreachableLight{
    background: #9c9c9c94;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABBCAYAAACdIImxAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDA3MEY0RjI1MTBCMTFFOUExODdDMDM4QzcyMUQ1OTMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDA3MEY0RjM1MTBCMTFFOUExODdDMDM4QzcyMUQ1OTMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMDcwRjRGMDUxMEIxMUU5QTE4N0MwMzhDNzIxRDU5MyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMDcwRjRGMTUxMEIxMUU5QTE4N0MwMzhDNzIxRDU5MyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PojHl7AAAABtSURBVHja7NoxCsAgFETBCGm0TR0E73+/tPEEMbU7A17gVw/cMnq/DwAgTJEAAJCaAJczfDvne5wBgO0SoDnDOgGqMwBAYif5LQEACQAApCSAwcSCwQQAeyaAwcSPBDCYAIDITvJbAgBxXgEGADL+BkXr/WoEAAAAAElFTkSuQmCC);
}
.reachableLight span{
    color: white;
}

.lightIconLeft{
    margin-top: 4px;
}

.lightIconLeftSpinner{
    margin-left: 10px!important;
    margin-top: -5px!important;
}

.lighNameCheckBox{
    /*padding: 0px 6px 0 21px!important;*/
    margin: -13px -4px -14px 0;
}

















.colorimation {
    -webkit-animation:colorchange 20s infinite alternate;
}

@-webkit-keyframes colorchange {
    0% {

        color: blue;
    }

    10% {

        color: #8e44ad;
    }

    20% {

        color: #1abc9c;
    }

    30% {

        color: #d35400;
    }

    40% {

        color: blue;
    }

    50% {

        color: #34495e;
    }

    60% {

        color: blue;
    }

    70% {

        color: #2980b9;
    }
    80% {

        color: #f1c40f;
    }

    90% {

        color: #2980b9;
    }

    100% {

        color: pink;
    }
}
